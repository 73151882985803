<template>
  <div class="modal-body" :class="{ 'burn-card-container-ru': $i18n.locale === 'ru' }">
    <div class="exchange-rate-block owned">
      <span class="rate-heading"
        >{{ $t('appStatisticOwnedPearls', { label: $t(token.label, { count: 3 }).toUpperCase() }) }}:</span
      >
      <span>{{ format(tokenBalance) }}</span>
    </div>
    <div class="exchange-rate-block">
      <span class="rate-heading">{{ $t('appStatisticRate') }}:</span>
      <span
        ><img class="icon-image" :src="imgSrc" loading="lazy" width="32" alt="Token Icon" />
        {{ ` ${tokenMultiplier || 1}` }}
      </span>
      =
      <span>
        <img class="menu-icon" :src="tokens[tokenPair as Tokens] as string" loading="lazy" alt="" width="32" />
        {{ `  ${rate}` }}
      </span>
    </div>
    <div class="exchange-block">
      <div class="exchange-rate-block seasonPoints">
        <span>
          <el-input-number
            v-model="tokenAmountToExchange"
            :step="tokenMultiplier || 1"
            :step-strictly="true"
            :validate-event="false"
            :min="tokenMultiplier || 0"
            aria-label="exchange"
            :max="maxAmount || 0"
          />
        </span>
        <button
          v-if="!$device.isMobile"
          class="btn-max btn-outline"
          @click="
            tokenAmountToExchange = BigNumber(maxAmount || 0)
              .integerValue(BigNumber.ROUND_FLOOR)
              .toNumber()
          "
        >
          MAX
        </button>

        <span class="exchange-equal">=</span>
        <span>
          <img class="menu-icon" :src="tokens[tokenPair as Tokens] as string" loading="lazy" alt="" width="32" />
          {{
            ` ${
              tokenPair === tokensConfig.seasonPoints.name.contract
                ? BigNumber(tokenAmountToExchange)
                    .dividedBy(tokenMultiplier || 1)
                    .multipliedBy(rate)
                    .integerValue(BigNumber.ROUND_FLOOR)
                    .decimalPlaces(2)
                    .toNumber()
                : exchangeAmount.toFixed(2)
            } `
          }}
        </span>
      </div>
      <div v-if="!!amountInputError">
        <span class="inputError">{{ amountInputError }}</span>
      </div>

      <button v-if="$device.isMobile" class="btn-max btn-outline" @click="tokenAmountToExchange = maxAmount || 0">
        MAX
      </button>
    </div>

    <button
      class="btn-primary w-button exchange-btn"
      :class="{ small: !$device.isMobile }"
      :loading="sellPearlTxLoading"
      :disabled="exchangeDisabled || !!amountInputError"
      @click="callExchange"
    >
      {{ $t('appStatisticExchangeButton') }}
    </button>
  </div>
</template>
<script setup lang="ts">
import { BigNumber } from 'bignumber.js';
import tokens from '~/public/img/tokens';
import type { TNullable } from '~/types/common';
import { tokensConfig, type Tokens } from '~/utils/constants';

const { blockchain } = useEnvs();
const { isMobile } = useDevice();

const { t } = useI18n();

const props = defineProps<{
  tokenBalance: string;
  rate: number;
  tokenAddress: string;
  tokenMultiplier?: number;
  tokenPair: string;
  icon?: string;
  maxAmount?: number;
  exchange: (tokenAmountToExchange: number) => Promise<void>;
}>();
const tokenAmountToExchange = ref(props.tokenMultiplier || 1);
const amountInputError = ref<TNullable<string>>(null);
const sellPearlTxLoading = ref(false);
const exchangeDisabled = ref(false);
const exchangeAmount = computed(() =>
  BigNumber(tokenAmountToExchange.value).multipliedBy(props.rate).isNaN()
    ? 0
    : BigNumber(tokenAmountToExchange.value).multipliedBy(props.rate).toNumber()
);
const token = computed(() => tokenMetaData(blockchain.contracts, props.tokenAddress));
const imgSrc = props.icon ? props.icon : (tokens[token.value.name.contract as Tokens] as string);

const validateAmountInput = (cur?: number) => {
  if ((typeof cur !== 'undefined' && cur > Number(props.tokenBalance)) || Number(props.tokenBalance) < 1) {
    amountInputError.value = t('appStatisticAmountInputError', {
      label: t(token.value.label, { count: 3 }).toLowerCase()
    });
  } else if (typeof cur !== 'undefined' && cur < (props.tokenMultiplier || 1)) {
    amountInputError.value = t('appStatisticMinAmountToExchange', {
      label: t(token.value.label, { count: 3 }).toLowerCase(),
      amount: props.tokenMultiplier || 1
    });
  } else {
    amountInputError.value = null;
  }

  const input = document.querySelector<HTMLElement>('input[aria-label="exchange"]');
  if (typeof cur === 'undefined' || !input) return;
  if ((cur?.toString().length || 0) > 9 && isMobile) {
    input.style.fontSize = '20px';
  } else if ((cur?.toString().length || 0) > 9 && !isMobile) {
    input.style.fontSize = '18px';
  } else if ((cur?.toString().length || 0) > 6 && isMobile) {
    input.style.fontSize = '28px';
  } else if ((cur?.toString().length || 0) > 6 && !isMobile) {
    input.style.fontSize = '24px';
  } else {
    input.style.fontSize = '32px';
  }
};

const callExchange = async () => {
  if (exchangeDisabled.value || amountInputError.value) return;
  exchangeDisabled.value = true;
  await props.exchange(tokenAmountToExchange.value);
  exchangeDisabled.value = false;
};

watch(tokenAmountToExchange, validateAmountInput, { immediate: true });
</script>
<style lang="scss" scoped>
.burn-card-container-ru {
  font-size: 19px;
}

.modal-body {
  text-align: center;
  font-size: 24px;
  color: var(--second-text-color);
  font-weight: 500;
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    margin-top: 35%;
  }
}

.exchange-rate-block {
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 10px;
  color: var(--main-text-color);

  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .rate-heading {
    color: var(--second-text-color);
  }
}

.exchange-rate-block.owned {
  span:not(.rate-heading) {
    font-weight: 700;
  }
}

.exchange-equal {
  line-height: 0.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.exchange-block {
  border-radius: 12px;
  border: 1px solid #1e353e;
  width: 100%;
  background: linear-gradient(180deg, #0083ad 0%, #00354f 100%), #081b25;
  font-size: 32px;
  color: var(--main-text-color);
  font-weight: 700;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  .exchange-rate-block {
    @media screen and (max-width: 500px) {
      font-size: 28px;
      line-height: 1;
      flex-direction: column-reverse;
      gap: 0;
    }
  }
}

.exchange-btn {
  padding: 13px 30px;
  line-height: 36px;
  width: 210px;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    bottom: 15px;
  }
}

.btn-max {
  border-radius: 12px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 50px;
  line-height: 12px;
  width: max-content;
}

.icon-image {
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
</style>

<style lang="scss">
.exchangeModal.exchangeModalMobile {
  .el-dialog__title {
    font-family: Grenze, sans-serif;
    font-size: 30px;
    font-weight: 700;
  }

  .modal-body {
    font-family: Grenze, sans-serif;
  }

  .exchange-rate-block {
    font-size: 22px;
  }

  .exchange-rate-block.owned {
    font-family: Eczar, sans-serif;
    font-size: 20px;
  }

  .exchange-rate-block.seasonPoints {
    span {
      font-size: 28px;
    }
  }

  .el-input {
    height: 48px;
  }

  .el-input__inner {
    font-size: 20px;
    font-weight: 700;
  }

  .exchange-btn {
    font-size: 20px;
  }

  .btn-max {
    height: 45px;
  }
}
.exchangeModal {
  .el-input-number {
    border: 1px solid #fff;
    border-radius: 12px;
    font-family: Eczar, sans-serif;
    font-weight: 400;
    width: 220px !important;

    @media screen and (max-width: 500px) {
      min-width: 180px;
      width: 100% !important;

      &__decrease,
      &__increase {
        width: 55px;
      }
    }

    &__decrease,
    &__increase {
      font-size: 20px;
      font-weight: 700;
      background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
      top: 0;
      bottom: 0;
      color: var(--main-text-color);
      &.is-disabled {
        color: var(--el-disabled-text-color);
      }
    }

    &__decrease {
      border-radius: 12px 0 0 12px;
      left: 0;
    }

    &__increase {
      border-radius: 0 12px 12px 0;
      right: 0;
    }

    .el-input {
      font-size: 32px;
      line-height: 1;
      overflow: hidden;
      border-radius: 12px;

      &__inner {
        --el-input-inner-height: 32px;
      }

      &__wrapper {
        border-radius: 12px;
        color: #fff;

        input {
          color: #fff;
          min-width: 180px;
        }

        background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%) !important;

        box-shadow: none;
        padding: 6px 11px;

        @media screen and (max-width: 500px) {
          padding: 5px 50px;
        }
      }
    }
  }

  &.el-dialog {
    padding: 16px 25px;
    max-width: 620px;

    &__title,
    &__header {
      font-size: 32px;
    }

    .el-dialog__body {
      max-width: 100%;
      padding: 0;
    }
  }

  .inputError {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: red;
  }
}
</style>
